/** 
 * Don't propagate errors to the parent.
 * Sentry will log this.
 * For specific/known cases log a warning so people have a clue what is happening.
 */
window.addEventListener('error', function (event) {
  
    event.preventDefault();

    if (event.message === 'Uncaught SyntaxError: Failed to execute \'postMessage\' on \'Window\': Invalid target origin \'\' in a call to \'postMessage\'.') {
        window.console.warn('You are running flare.js from a non supported protocol (like file://). Please contact support@salesflare.com for more info or any help.')
    }
});